import React from 'react';
import {
    Card,
    Col,
    Form,
    Input,
    Row,
    Divider,
    Checkbox,
    Space,
    Select,
} from 'antd';
import { FileUpload } from 'components/organisms/ProgramaRecompensas/FileUpload';
import styles from './GeneralInfo.module.css';
import PropTypes from 'prop-types';

const GeneralInfo = ({ imageUrl, disabled }) => {
    const form = Form.useFormInstance();

    return (
        <Card
            type="inner"
            title="Información general"
            className={styles.wrapper}>
            <Form form={form}>
                <Row justify="center">
                    <Col span={11}>
                        <Form.Item
                            label="Nombre de la promoción"
                            name="promotionName"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor, ingrese el nombre de la promoción.',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Space>
                            <Form.Item
                                label="Mostrar como"
                                name="showAs"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor, seleccione una opción.',
                                    },
                                ]}>
                                <Select
                                    disabled={disabled}
                                    placeholder="Seleccionar"
                                    options={[
                                        {
                                            label: 'Productos',
                                            value: 'products',
                                        },
                                        {
                                            label: 'Franquicias',
                                            value: 'franchises',
                                        },
                                    ]}
                                    className={styles.select}
                                />
                            </Form.Item>
                            <Form.Item
                                className={styles.item}
                                name="showHome"
                                valuePropName="checked">
                                <Checkbox
                                    disabled={disabled}
                                    className={styles.checkbox}>
                                    Mostrar banner en la Home
                                </Checkbox>
                            </Form.Item>
                        </Space>
                    </Col>

                    <Col span={1}>
                        <Row
                            justify="center"
                            className={styles.dividerContainer}>
                            <Col>
                                <Divider
                                    type="vertical"
                                    className={styles.divider}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <FileUpload name="image" initialImage={imageUrl} />
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default GeneralInfo;

GeneralInfo.propTypes = {
    imageUrl: PropTypes.string,
    disabled: PropTypes.bool,
};
