import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Select, message, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'config/axios';
import { urls } from 'constants/index';
import getErrorMessage from 'components/utils-components/getErrorMessage';
import PropTypes from 'prop-types';

// Conjunto de "selects" para Ciudad y Barrio dentro del "collapse" de "Push Zonas"
// * "tipoZona" retorna un numero que indica si la zona seleccionada es "Zona
//    Geografica", que al detectar cambio obtiene la lista de ciudades o bien realiza
//    la limpieza del componente.
// * "pushForm" permite acceso al "formulario" se utiliza para establecer datos
//    programaticamente.
// * "fields" contienen los datos del "formulario", se utiliza para detectar su contenido
//    "null", lo que significa que se presiono el boton de "restablecer" y ejecutar las
//    instrucciones de limpieza del componente.

const PushZonaMapGeograficaPersonalizada = ({
    tipoZona,
    pushForm,
    fields,
    setCantidadUsuariosSeleccionados,
    disabled,
}) => {
    // State para "select" de cuidades
    const [ciudades, setCiudades] = useState(); // Datos a mostrar en el select
    const [deshabilitarCiudades, setDeshabilitarCiudades] = useState(true);
    const [cargandoCiudades, setCargandoCiudades] = useState();

    // State para "select" de cuidades
    const [barrios, setBarrios] = useState(); // Datos a mostrar en el "select"
    const [deshabilitarBarrios, setDeshabilitarBarrios] = useState(true);
    const [cargandoBarrios, setCargandoBarrios] = useState();
    const [requiredBarrios, setRequiredBarrios] = useState(false);

    // Obtener datos de "cuidades" del "endpoint"
    const obtenerCiudades = async () => {
        let arrCiudades = [];

        try {
            setCargandoCiudades(true);

            const respuesta = await axios.get('/city_names');

            respuesta.data.data.cities.map((item) => arrCiudades.push(item));
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de las ciudades',
            );
            console.log(
                'Hubo un problema al obtener los datos de las ciudades',
                error,
            );
        }

        setCargandoCiudades(false);

        if (arrCiudades.length > 0) {
            setCiudades(arrCiudades);
            setDeshabilitarCiudades(false);
        } else {
            setCiudades(null);
            setDeshabilitarCiudades(true);
        }
    };

    // Manejar cambio de "ciudad" en el "select"
    function handleChangeCiudades(value) {
        if (value && Number(value)) {
            setDeshabilitarBarrios(false);
            obtenerBarrios(value);
            setRequiredBarrios(true);
        } else {
            setDeshabilitarBarrios(true);
        }
        pushForm.setFieldsValue({ barrio: null });
    }

    // Limpiar seleccion de "ciudad, barrio" del "select" en el formulario
    const handleClearCity = () => {
        pushForm.setFieldsValue({ ciudad: null, barrio: null });
        setBarrios(null);
        setDeshabilitarBarrios(true);
        setRequiredBarrios(false);
    };

    // Obtener datos de "barrios" del "endpoint"
    const obtenerBarrios = async (cityId) => {
        let arrBarrios = [];

        try {
            setCargandoBarrios(true);

            const respuesta = await axios.get('/neighborhoods', {
                params: { city_id: cityId },
            });

            respuesta.data.data.neighborhoods.map((item) =>
                arrBarrios.push(item),
            );
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de las barrios',
            );
        }

        setCargandoBarrios(false);

        if (arrBarrios.length > 0) {
            setBarrios(arrBarrios);
            setDeshabilitarBarrios(false);
            setRequiredBarrios(true);
        } else {
            setBarrios(null);
            setDeshabilitarBarrios(true);
            setRequiredBarrios(false);
        }
    };

    const handleChangeBarrios = () => {
        const ciudad = Number(pushForm.getFieldsValue(['ciudad']).ciudad);
        const barrio = Number(pushForm.getFieldsValue(['barrio']).barrio);
        obtenerCantidadUsuarios(ciudad, barrio);
    };

    // Obtener cantidad de usuarios por zona geografica
    const obtenerCantidadUsuarios = async (citiesId, neighborhoodsId) => {
        let cantidadUsuarios = 0;

        try {
            let data = {};
            data['axiosBaseURL'] = urls.REACT_APP_BACKEND_URL_C;
            data['cities_id'] = [citiesId];
            data['neighborhoods_id'] = [neighborhoodsId];

            const respuesta = await axios.post('/get_users_for_push', data);

            respuesta.data.data.forEach((item) => {
                try {
                    cantidadUsuarios += Number(item.count);
                } catch (e) {
                    cantidadUsuarios += 0;
                }
            });
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'Hubo un problema al obtener el alcance de esta zona',
            );
            message.error(errorMessage);
        }

        setCantidadUsuariosSeleccionados(cantidadUsuarios);
    };

    // Limpiar seleccion de "ciudad, barrio" del "select" en el formulario
    const limpiarFormSelect = () => {
        // Limpiar datos de los selects Zona Geografica
        setCiudades(null);
        setDeshabilitarCiudades(true);
        pushForm.setFieldsValue({ ciudad: null });
        setBarrios(null);
        setDeshabilitarBarrios(true);
        setRequiredBarrios(false);
        pushForm.setFieldsValue({ barrio: null });
    };

    // Ejecutar limpieza de la seleccion de "cuidad, barrio" al detectar cambio en "tipoZona"
    useEffect(() => {
        if (fields === null) {
            limpiarFormSelect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoZona, fields]);

    // Obtener lista de "ciudades" al cargar el componentes
    useEffect(() => {
        if (tipoZona === '2') {
            obtenerCiudades();
        }
    }, [tipoZona]);

    return (
        <Row gutter="24" align="middle">
            <Col span="9">
                <Form.Item name="ciudad" label="Ciudad">
                    <Select
                        name="ciudad"
                        placeholder="Seleccionar"
                        showSearch
                        optionFilterProp="children"
                        loading={cargandoCiudades}
                        disabled={deshabilitarCiudades || disabled}
                        onChange={handleChangeCiudades}
                        filterOption={(input, option) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }>
                        {ciudades?.map((item) => (
                            <Select.Option
                                key={item.city_id}
                                value={item.city_id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span="9">
                <Form.Item
                    name="barrio"
                    label="Barrio"
                    rules={[
                        {
                            required: requiredBarrios,
                            message: 'Este campo es requerido',
                        },
                    ]}>
                    <Select
                        name="barrio"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Seleccionar"
                        loading={cargandoBarrios}
                        disabled={deshabilitarBarrios}
                        onChange={handleChangeBarrios}
                        filterOption={(input, option) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }>
                        {barrios?.map((item) => (
                            <Select.Option
                                key={item.city_id}
                                value={item.city_id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span="6">
                <Button
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    disabled={deshabilitarBarrios}
                    onClick={handleClearCity}
                />
            </Col>
        </Row>
    );
};

export default PushZonaMapGeograficaPersonalizada;

PushZonaMapGeograficaPersonalizada.propTypes = {
    pushForm: PropTypes.object,
    fields: PropTypes.any,
    setCantidadUsuariosSeleccionados: PropTypes.func,
    tipoZona: PropTypes.any,
    setTipoZona: PropTypes.func,
    setRestablecerDeeplink: PropTypes.func,
    setAlcanceLoading: PropTypes.func,
    disabled: PropTypes.bool,
};
