import React, { useEffect, useState } from 'react';
import { Upload, Form } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import styles from './Promociones/BannersPromo/GeneralInfo.module.css';
import PropTypes from 'prop-types';

export const FileUpload = ({ name, initialImage }) => {
    const form = Form.useFormInstance();
    const { Dragger } = Upload;

    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');

    // Decode file name from URL
    const getImageName = (url) => {
        const fullName = url.split('/').pop();
        const baseName = fullName.split('_')[0];
        return decodeURIComponent(baseName);
    };

    useEffect(() => {
        if (initialImage) {
            setFileList([
                {
                    uid: '-1',
                    name: getImageName(initialImage),
                    status: 'done',
                    url: initialImage,
                },
            ]);
            setPreviewImage(initialImage);
            if (form) {
                form.setFieldsValue({ [name]: initialImage });
            }
        }
    }, [initialImage, form, name]);

    const onChange = (info) => {
        let newFileList = [...info.fileList];
        newFileList = newFileList.slice(-1);

        if (newFileList[0]) {
            const { originFileObj } = newFileList[0];

            const objectUrl = URL.createObjectURL(originFileObj);
            setPreviewImage(objectUrl);
        }

        form.setFieldsValue({ [name]: newFileList });
        setFileList(newFileList);
    };

    const onRemove = () => {
        setFileList([]);
        setPreviewImage('');
        form.setFieldsValue({ [name]: [] });
    };

    return (
        <Form.Item
            name={name}
            rules={[
                { required: true, message: 'Por favor, subí una imagen.' },
            ]}>
            <Dragger
                className={styles.dragger}
                multiple={false}
                maxCount={1}
                beforeUpload={() => false}
                onChange={onChange}
                onRemove={onRemove}
                fileList={fileList}
                accept="image/png, image/jpeg, image/jpg">
                {previewImage ? (
                    <img
                        src={previewImage}
                        alt={name}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                ) : (
                    <>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            <span className={styles.requiredTick}>* </span>
                            Hacé clic o arrastrá tu banner acá
                        </p>
                        <p className="ant-upload-hint">
                            El archivo debe ser de 1800x900 px (jpg, jpeg o png)
                        </p>
                    </>
                )}
            </Dragger>
        </Form.Item>
    );
};

FileUpload.propTypes = {
    name: PropTypes.string.isRequired,
    initialImage: PropTypes.string,
};
